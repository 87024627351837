import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import './Login.css';

function Login() {
  const handleInstagramLogin = () => {
    const instagramClientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_INSTAGRAM_REDIRECT_URI);
    const scope = encodeURIComponent('business_basic,business_manage_messages,business_manage_comments,business_content_publish');
    
    const authUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${instagramClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
    
    window.location.href = authUrl;
  };

  return (
    <div className="login-container">
      <h1>Welcome to EchoModerator</h1>
      <p>Please log in with your Instagram account to continue.</p>
      <button onClick={handleInstagramLogin} className="instagram-login-button">
        <FaInstagram /> Login with Instagram
      </button>
    </div>
  );
}

export default Login;