import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import './HeaderLoggedIn.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faThumbsDown, faQuestion, faCommentSlash } from '@fortawesome/free-solid-svg-icons';

const HeaderLoggedIn = ({ onMediaUpdate }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationCounts, setNotificationCounts] = useState({
    hates: 0,
    negatives: 0,
    questions: 0,
    unanswered: 0
  });

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await fetch('/api/update');
        if (response.ok) {
          const data = await response.json();
          setNotificationCounts(data.notification_counts);
          if (data.media.length > 0) {
            // Pass media updates to parent component or context
            if (onMediaUpdate) {
              onMediaUpdate(data.media);
            }
          }
        } else {
          console.error('Failed to fetch updates');
        }
      } catch (error) {
        console.error('Error fetching updates:', error);
      }
    };

    fetchUpdates(); // Initial fetch

    const intervalId = setInterval(fetchUpdates, 5000); // Poll every 5 seconds

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [onMediaUpdate]);

  return (
    <header className="header-logged-in media-item">
      <div className="header-content">
        <div className="header-left">
          <img src="/images/logo.svg" alt="EchoModerator Logo" className="logo" />
          <h1 className="app-name">EchoModerator</h1>
        </div>
        <div className="header-center">
          <button className="notification-button hate-speech">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>Hate</span>
            <span className="notification-count">{notificationCounts.hates}</span>
          </button>
          <button className="notification-button negativity">
            <FontAwesomeIcon icon={faThumbsDown} />
            <span>Negativity</span>
            <span className="notification-count">{notificationCounts.negatives}</span>
          </button>
          <button className="notification-button questions">
            <FontAwesomeIcon icon={faQuestion} />
            <span>Questions</span>
            <span className="notification-count">{notificationCounts.questions}</span>
          </button>
          <button className="notification-button unanswered">
            <FontAwesomeIcon icon={faCommentSlash} />
            <span>Unanswered</span>
            <span className="notification-count">{notificationCounts.unanswered}</span>
          </button>
        </div>
        <div className="header-right">
          <div className="dropdown">
            <button onClick={toggle} className="btn btn-primary menu-button">
              <FontAwesomeIcon icon="bars" /> Menu
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu show">
                <Link to="/dashboard" className="dropdown-item bold">
                  Manage Comments
                </Link>
                <Link to="/subscription" className="dropdown-item">
                  My Subscription
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/logout" className="dropdown-item">
                  Logout
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderLoggedIn;
